'use client';
import karePediaLogoGifFirst from '@/public/karePedia/karePediaFirstLogo.gif';
import karePediaLogoGifSecond from '@/public/karePedia/karePediaSecondLogo.gif';
import karePediaLogoGifFirstDark from '@/public/karePedia/karePediaFirstLogoDark.gif';
import karePediaLogoGifSecondDark from '@/public/karePedia/karePediaSecondLogoDark.gif';
import Image from 'next/image';
import { useState } from 'react';

type Props = {
  isWhiteLogo?: Boolean;
};

export const KarePediaLogo = ({ isWhiteLogo }: Props) => {
  const [logo, setLogo] = useState(
    isWhiteLogo ? karePediaLogoGifFirstDark : karePediaLogoGifFirst
  );
  const [darklogo, setDarkLogo] = useState(karePediaLogoGifFirstDark);
  const logoChangerFunc = () => {
    setTimeout(() => {
      setLogo(
        isWhiteLogo ? karePediaLogoGifSecondDark : karePediaLogoGifSecond
      );
      setDarkLogo(karePediaLogoGifSecondDark);
    }, 4000);
  };
  return (
    <>
      <Image
        src={logo}
        alt=''
        className={'h-[55px] w-auto dark:hidden max-sm:h-[45px]'}
        onLoad={logoChangerFunc}
	unoptimized
      />
      <Image
        src={darklogo}
        alt=''
        className={'hidden h-[55px] w-auto dark:block max-sm:h-[45px]'}
        onLoad={logoChangerFunc}
	unoptimized
      />
    </>
  );
};
