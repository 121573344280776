'use client';
// import React, { useEffect } from "react";
import Modal from '@mui/material/Modal';
// import Button from "@mui/material/Button";
import { Grid, Typography } from '@mui/material';
import useThemeStore from '@/app/stores/useThemeStore';

function ToastMessage() {
  const { showToast, setShowToast } = useThemeStore((state) => state);
  const handleClose = () => setShowToast(false);

  if (showToast) {
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        keepMounted
        open={showToast}
        onClose={handleClose}
        aria-labelledby='keep-mounted-modal-title'
        aria-describedby='keep-mounted-modal-description'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginTop: { lg: '4%', xs: '10%' },
        }}
      >
        <Grid
          sx={{
            width: 780,
            margin: { lg: 2, xs: 2 },
            padding: { lg: '0px', xs: '5px' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 78,
            borderRadius: 2,
            background: 'linear-gradient(to right, #ffd357, #0ee686, #0b8edc)',
          }}
        >
          <Grid
            style={{
              backgroundColor: '#222a39',
              width: '99%',
              height: '90%',
              borderRadius: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontWeight: 500,
                fontSize: { lg: 22, xs: 18 },
                textAlign: 'center',
              }}
            >
              Thank you for trusting us! We&#39;ll be in touch with you shortly.
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

export default ToastMessage;
