import Link from 'next/link';
import { categoryIcons } from './CategoryIcon';
import Image from 'next/image';

export default function NavCategoryIcon(props: any) {
  const category = props.attributes?.slug;
  const categoryImages = props.attributes.icon.data?.attributes.url;
  return (
    <Link
      className={`group box-border flex cursor-pointer flex-col items-center border-[#00b4b9] pt-[16px] hover:border-t-[3px] hover:pt-[13px] sm:mr-[20px] sm:w-auto xl:mr-[40px] ${props.border}`}
      href={category === 'for-you' ? '/' : `/search/${category}`}
    >
      <section className=' m-auto mb-[10px] h-[30px] w-[30px]'>
        {categoryImages ? (
          <Image
            src={categoryImages}
            height={20}
            width={20}
            alt='category icon'
            className={'m-auto '}
          />
        ) : (
          categoryIcons?.ForYou
        )}
      </section>

      <p className=' flex-shrink-0 whitespace-nowrap text-[13px] font-medium text-[#1c2534] dark:text-[#9ea8ac] dark:group-hover:text-white'>
        {props.attributes?.name}
      </p>
    </Link>
  );
}
