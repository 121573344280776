import React, { ReactNode } from 'react';
import Link from 'next/link';
import { KarePediaLogo } from './KarePediaLogo';
import Image from 'next/image';

type Props = {
  isWhiteLogo?: Boolean;
};

export default function Logo({ isWhiteLogo }: Props) {
  const platform = process.env.NEXT_PUBLIC_PLATFORM;
  let Logo: ReactNode;
  switch (platform) {
    case 'karepedia-blog':
      Logo = <KarePediaLogo isWhiteLogo={isWhiteLogo} />;
      break;
    default:
      Logo = (
        <>
          <Image
            src={
              isWhiteLogo
                ? process.env.NEXT_PUBLIC_CASE_BLOG_WHITE_LOGO!
                : process.env.NEXT_PUBLIC_CASE_BLOG_BLACK_LOGO!
            }
            alt='karetrip-logo'
            className={'h-[30px] w-auto dark:hidden max-sm:h-[22px]'}
            width={100}
            height={100}
          />
          <Image
            src={process.env.NEXT_PUBLIC_CASE_BLOG_WHITE_LOGO!}
            alt='karetrip-logo'
            className={'hidden h-[33px] w-auto dark:block max-sm:h-[22px]'}
            width={100}
            height={100}
          />
        </>
      );
  }
  return (
    <section className='w-1/4 max-lg:w-full'>
      <a
        href={`https://${process.env.NEXT_PUBLIC_DOMAIN_URL!}`}
        className='h-[46px] w-auto cursor-pointer'
      >
        {Logo}
        <span className='sr-only'>Logo</span>
      </a>
    </section>
  );
}
